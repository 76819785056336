html {
  position: relative;
  min-height: 100%;
}
body {
  margin-bottom: 60px;
}

.footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 60px;
    line-height: 60px;
    text-align: center;
}

.main-container {
    margin: 0px 40px;
}

img.logo {
    display: block;
    margin-top: 20px;
}

.loader {
    border: 10px solid #f3f3f3;
    -webkit-animation: spin 1s linear infinite;
    animation: spin 1s linear infinite;
    border-top: 10px solid #555;
    border-radius: 50%;
    width: 120px;
    height: 120px;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.has-notify-icon-small {
    -ms-background-size: contain;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    width: 15px;
    height: 15px;
    display: block;
}

.printed {
    background-image: url(assets/images/printed.png);
}

.table td, .table th {
    padding: .3em;
    vertical-align: middle;
    color: black
}

.table-bordered{
    border:2px solid #939b9e;
    width: auto;
}

#popUpOverlay {
  position: absolute;
  left: 0px;
  top: 0px;
  width:100%;
  height:100%;
  text-align:center;
  z-index: 1000;
}

#popUpOverlay .popUpContent {
  width:500px;
  margin: 50px auto;
  background-color: #fff;
  border-radius: 5px;;
  padding:15px;
  text-align:center;
  box-shadow: 0 0 10px rgba(0,0,0,0.5);
}

.simpleRfIdColor{
    background-color: rgb(84, 143, 214);
}

.field-center{
    line-height: 40px;
}

.cursorFinger{
    cursor: pointer;
}

.ml-5 {
    margin-left: 5px !important;
}

/* IMPORTANT. Workaround to fix background for model pop */
ngb-modal-backdrop {
  z-index: 1050 !important;
}
